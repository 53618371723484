<template>
  <CRow>

    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-building" />
          <h5 class="d-inline ml-2">MenutePay Companies</h5>
          <div class="card-header-actions">
            <CButton color="primary" :to="{ name: 'Add New Company'}">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Company
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";

export default {
  name: "Companies",
  components: {
    BackendTable,
  },
};
</script>
