<template>
  <CCardBody>
    <alert-section
      :successAlertMessage="successAlertMessage"
      :dismissSecs="dismissSecs"
      :dismissSuccessAlert="dismissSuccessAlert"
      :errorAlertMessage="errorAlertMessage"
      :showErrorAlert="showErrorAlert"
    />

    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Country Filter -->
            <div class="col-lg-3 col-sm-5 col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                :value="$store.state.filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="countryFilter"
              />
            </div>

            <!-- Company Filter -->
            <div class="col-lg col-sm-7 col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Filter companies by..."
                v-model="selectedCompanyFilter"
                :options="[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]"
                :reduce="o => o.value"
                multiple
                :searchable="false"
                @input="companyFilter"
              />
            </div>

            <!-- Search -->
            <div class="col-lg col-filter mb-2 mb-lg-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <!--
      clickable-rows
      @row-clicked="rowClicked"
     -->
    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :column-filter="{ external: true, lazy: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No company found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >

      <!-- Check Box -->
      <template #select="{item}">
        <td>
          <CInputCheckbox
            :checked="item._selected"
            @update:checked="() => check(item)"
            custom
          />
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <td class="text-center">
          <CButton
            :to="{ name: 'Edit Company', params: { id: item.id } }"
            v-c-tooltip="{
              content: 'Edit',
              placement: 'top',
            }"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-align-left" />
          </CButton>
        </td>
      </template>

      <!-- id -->
      <template #id="{item}">
        <td>
          {{ item.id }}
        </td>
      </template>

      <!-- Postcode -->
      <template #postal_code="{ item }">
        <td class="align-middle">
          <CIcon class="mr-1" :content="$options.flagSet[item.country.flag]" size="lg" />
          {{ item.postal_code }}
        </td>
      </template>

      <!-- Manager -->
      <template #manager="{item}">
        <td>
          <CLink v-if="item.manager" :to="{ name: 'User', params: { id: item.manager.id } }" target="_blank">
            <div class="d-flex align-items-center py-1">
              <div class="c-avatar mr-2">
                <img :src="item.manager.image_icon || item.manager.gravatar" class="c-avatar-img" alt="">
              </div>
              <span class="text-muted">{{ item.manager.first_name }}</span>
            </div>
          </CLink>
        </td>
      </template>

      <!-- Name -->
      <template #name="{item}">
        <td>
          <strong>{{ item.name.toUpperCase() }}</strong>
        </td>
      </template>

      <!-- Monthly Limit -->
      <template #monthly_limit="{item}">
        <td class="text-center">
          <b>{{ parseFloat(item.monthly_limit) | toCurrency('remove_thousand_separator') }}</b>
        </td>
      </template>

      <!-- Balance -->
      <template #account_balance="{item}">
        <td :class="`text-${(item.total_payments - item.total_invoices) >= 0 ? 'success' : 'danger'}`" class="text-center">
          <b>{{ (item.total_payments - item.total_invoices) | toCurrency('remove_thousand_separator') }}</b>
        </td>
      </template>

      <!-- Created -->
      <template #created_at="{item}">
        <td>{{ moment(item.created_at).format('LL') }}</td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { flagSet } from '@coreui/icons'

export default {
  name: "BackendTable",
  flagSet,
  data() {
    return {
      loadedItems: [],
      fields: [
        // { key: "select",          label: "",              filter: false, sorter: false, _style: "width: 1%" },
        { key: "actions",         label: "",              filter: false, sorter: false, _style: "min-width: 40px; text-align: center;" },
        { key: "id",              label: "ID",            filter: false, _style: "min-width: 48px; width: 48px;" },
        { key: "postal_code",     label: "Postcode",      filter: false, _style: "min-width: 80px; width: 96px;" },
        { key: "manager",         label: "Manager",       filter: false, _style: "min-width: 100px;" },
        { key: "name",            label: "Name",          filter: false, _style: "min-width: 150px;" },
        { key: "monthly_limit",   label: "Monthly Limit", filter: false, _style: "text-align:center" },
        { key: "account_balance", label: "Balance",       filter: false, _style: "text-align:center" },
        { key: "created_at",      label: "Created",       filter: false },
        // { key: "address",         label: "Address",       filter: false, _style: "min-width: 100px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,
      filteredColumn: null,

      loading: false,

      // Alert işlemleri için
      successAlertMessage: {},
      dismissSecs: 10,
      dismissSuccessAlert: 0,
      errorAlertMessage: {},
      showErrorAlert: false,

      // Company Filter işlemleri için
      selectedCompanyFilter: ['active','inactive'],

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  mounted() {
    this.activePage =  this.queryPage;
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'Companies', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
  },

  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.MENUTEPAY.COMPANIES.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          search: this.search,
          filter: this.selectedCompanyFilter,
          country: this.$store.state.filters.country,
          'order-by': this.orderBy,
          direction: this.direction,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            Object.keys(items.data).map(function(key, index) {
              let item = items.data[index];
              item._classes = (item.eol == "1") ? 'strikeout' : '';
            });

            this.loadedItems = items.data.map((el) => {
              return {
                ...el,
                country: this.authCountries.find((c) => c.value === el.countryId),
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    rowClicked (item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'svg', 'path', 'A', 'IMG'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check (item) {
      this.$set(item, "_selected", !item._selected);
      if (item._selected) {
        // Sadece bir adet item seçilsin.
        if(this.selectedItem) {
          this.selectedItem._selected = false;
          this.selectedItem._classes = this.selectedItem._classes.replace(" table-selected", "");
        }

        item._classes = item._classes.concat(" table-selected");
        this.selectedItem = item;
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItem = null;
      }
    },

    // Filters
    companyFilter(value) {
      this.selectedCompanyFilter = value;
      this.getAllItems();
    },
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.selectedCompanyFilter = ['active','inactive'];
        this.search = "";
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

/* table-filter */
@media (min-width: 576px) {
  label.mfe-2 + input[type="text"] {
    width: 70% !important;
  }
}
</style>

<style scoped>
.badge {
  font-weight: 500;
  line-height: 1.5;
  border-radius: 0;
}
.badge-first {
  border-right: 1px solid #fff;
}
.badge.notify {
  position: absolute;
  left: 25px;
  top: 10px;
  border-radius: 30px;
}
.copy-features .modal-header {
  padding: 0.75rem !important;
}
.copy-features .modal-footer {
  padding: 0.25rem !important;
}
</style>
